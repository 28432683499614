.admin-login {
  img {
    height: 100px;
  }

  .forgot-pass-link {
    font-size: 15px;
    color: white;
    font-weight: 500;
    text-decoration: underline;
    text-transform: capitalize;
  }

  p {
    //text-align: center;
    color: white;
    font-size: 14px;
    font-weight: 500;
    opacity: 1;

    &.forgot-pass {
      font-size: 16px;
    }

    a {
      color: white;
      text-decoration: underline;
    }
  }

  .packages {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
    }

    .package {
      border-radius: 8px;
      padding: 16px;
      background: rgba($color: #000, $alpha: 0.3);
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 1px solid rgba($color: #ffff, $alpha: 0.5);
      cursor: pointer;
      transition:
        0.4s background-color,
        0.4s border-color;

      &.selected {
        background-color: black;
        border-color: var(--primary-color);
      }

      h3 {
        color: white;
        text-align: center;
      }

      p {
        color: white;
        opacity: 0.5;
        text-align: center;
      }
    }
  }

  .error {
    position: relative;
    margin-bottom: 10px;
  }
}
