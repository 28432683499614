.genology-tree {
  display: flex;

  gap: 35px;
  margin-bottom: 35px;

  .col {
    flex: 1;
    display: flex;
    flex-direction: column;

    &.left-col {
      max-width: 320px; 
    }
  }


}

