$mobile-media: "max-width: 850px";
$tablet-media: "max-width: 1250px";

.dashboard {
  background: black;
  height: 100%;
  margin-bottom: 150px;
  font-family:
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif;

  p {
    font-family:
      Arial,
      Helvetica Neue,
      Helvetica,
      sans-serif;
  }

  div {
    font-family:
      Arial,
      Helvetica Neue,
      Helvetica,
      sans-serif;
  }

  &.loading {
    filter: blur(2px);
  }

  .header {
    display: flex;
    align-items: center;
    margin: auto;
    background-color: #030407;
    border: 1px #3e404c;
    border-bottom-style: solid;
    padding: 17px 3%;
    position: static;

    .sponsor-name {
      flex-direction: row;
      gap: 5px;
    }

    .container-navigation {
      max-width: 1140px;
      justify-content: space-between;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      width: 100%;
      flex-direction: row;

      .navigation-left {
        width: 35%;
        align-items: center;
        display: flex;
      }

      .navigation-right {
        width: 65%;
        order: 1;
        justify-content: flex-end;
        align-items: center;
        display: flex;
      }
    }

    .founders-logo {
      height: 43px;
    }

    .login-container {
      display: flex;
      gap: 20px;
      justify-content: flex-end;
      width: 100%;
      align-items: center;

      .log-buttons {
        display: flex;
        gap: 20px;

        .btn {
          min-width: fit-content;
          width: 100%;
          display: flex;
          justify-content: space-around;
          gap: 7px;
          padding: 10px;
        }
      }
    }
  }

  .page {
    padding: 57px 5% 100px;
    position: static;
    mix-blend-mode: normal;
    background-color: #000;
    overflow-x: hidden;

    .container {
      z-index: 1;
      width: 100%;
      max-width: 1140px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      margin-bottom: 20px;

      .body {
        display: flex;
        flex: 1 1 0%;
        width: 100%;
        height: 100%;
        flex-direction: column;
        max-width: 1400px;
        margin: auto;

        .content-header {
          margin-top: 7px;

          .content-header-1 {
            font-size: 3rem;
            font-weight: 700;
            color: white;
            line-height: 1;
            font-family: Syne, sans-serif;
          }

          .content-header-2 {
            color: white;
            font-size: 3.3rem;
            font-weight: 700;
            margin-left: 20px;
            line-height: 1;
            font-family: Syne, sans-serif;

            @media ($mobile-media) {
              margin-left: 0;
            }
          }
        }

        .section-btn {
          width: 50%;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;

          @media ($mobile-media) {
            width: 100%;
          }
        }

        .content {
          max-width: none;
          min-width: 0;
          grid-column-gap: 5rem;
          grid-row-gap: 5rem;
          flex-direction: column;
          grid-template-rows: auto;
          grid-template-columns: 1fr 1fr;
          grid-auto-columns: 1fr;
          align-items: stretch;
          padding-bottom: 4rem;
          display: grid;
          margin-top: 50px;

          .content-description {
            grid-column-gap: 2rem;
            flex-direction: column;
            display: flex;
            line-height: 1;

            .content-description-1 {
              color: #ffbf42;
              font-size: 1em;
              text-transform: uppercase;
              letter-spacing: 0.1em;
              margin-bottom: 30px;
            }

            .content-description-2 {
              color: white;
              font-size: 1.25rem;
              margin-bottom: 30px;
            }

            .content-description-3 {
              color: white;
              font-size: 1.25rem;
              margin-bottom: 30px;
            }

            .content-description-4 {
              color: white;
              font-size: 1.25rem;
              margin-bottom: 30px;
            }

            .content-description-5 {
              color: white;
              font-size: 1.5rem;
              opacity: 0.7;
            }

            .content-description-6 {
              margin-top: 27px;
              color: gray;
              font-size: 1rem;
              opacity: 0.7;
            }
          }

          .img-wrap {
            height: 100%;
            grid-column-gap: 16px;
            grid-row-gap: 16px;
            border-radius: 2rem;
            grid-template-rows: auto auto;
            grid-template-columns: 1fr 1fr;
            grid-auto-columns: 1fr;
            justify-content: center;
            align-items: center;
            display: flex;
            position: relative;
            overflow: hidden;

            .img-1 {
              width: 100%;
              height: 100%;
              max-width: none;
              object-fit: cover;
              object-position: 50%;

            }

            .img-2 {
              width: 90%;
              /* height: 100%; */
              /* object-fit: scale-down; */
              /* object-position: 50% 0%; */

              transform: scale(0.8);
              transform: skew(-1deg, -1deg);
              box-shadow: 14px 11px 44px 2px rgba(163, 156, 43, 0.52);
              -webkit-box-shadow: 14px 11px 44px 2px rgba(163, 156, 43, 0.52);
              -moz-box-shadow: 14px 11px 44px 2px rgba(163, 156, 43, 0.52);
            }
          }

          .ejdalius {
            border-radius: 30px;
            background-image: url("ejdalius.png");
            background-position: top;
            height: 500px;
            background-repeat: no-repeat;
            width: 500px;
          }

          @media ($mobile-media) {
            padding-bottom: 0rem;
          }
        }

        .content1 {
          max-width: none;
          min-width: 0;
          grid-column-gap: 5rem;
          grid-row-gap: 5rem;
          flex-direction: column;
          grid-template-rows: auto;
          grid-template-columns: 1fr 1fr;
          grid-auto-columns: 1fr;
          align-items: stretch;
          padding-bottom: 4rem;
          display: grid;
          margin-top: 50px;

          @media ($mobile-media) {
            padding-bottom: 0rem;
          }

          @media ($mobile-media) {
            display: flex;
            grid-row-gap: 1rem;

            &.gap-lg {
              grid-row-gap: 5rem;
            }

            &.gap-md {
              grid-row-gap: 4rem;
            }
          }

          .content-description {
            grid-column-gap: 2rem;
            flex-direction: column;
            display: flex;
            line-height: 1;

            .content-description-1 {
              color: #ffbf42;
              font-size: 1.5em;
              text-transform: uppercase;
              letter-spacing: 0.1em;
              margin-bottom: 30px;
              display: flex;
              font-weight: bold;
            }

            .content-description-2 {
              color: white;
              font-size: 1.25rem;
              margin-bottom: 30px;
            }

            .content-description-3 {
              color: white;
              font-size: 1.25rem;
              margin-bottom: 30px;
              display: flex;
              margin-left: 20px;

              .circle-icon {
                color: #ffbf42;
                font-size: 17px;
                display: flex;
                justify-content: center;
                margin-right: 10px;
                margin-top: 5px;
              }
            }

            .content-description-4 {
              color: white;
              font-size: 1.25rem;
              margin-bottom: 30px;
            }

            .content-description-5 {
              color: white;
              font-size: 1.5rem;
              opacity: 0.7;
            }

            .content-description-6 {
              margin-top: 27px;
              color: gray;
              font-size: 1rem;
              opacity: 0.7;
            }
          }

          .img-wrap {
            height: 100%;
            border-radius: 2rem;
            justify-content: center;
            display: flex;
            position: relative;
            overflow: visible;
            flex-direction: column;

            @media ($tablet-media) {
              max-height: 40vw;
            }

            @media ($mobile-media) {
              min-height: 80vw !important;
              margin-bottom: -50px;
            }

            .img-2 {
              width: 93%;
              /* height: 100%; */
              /* object-fit: scale-down; */
              /* object-position: 50% 0%; */

              transform: scale(0.8);
              transform: skew(-1deg, -1deg);
              box-shadow: -4px -4px 21px -7px rgba(186, 153, 63, 0.72);
              -webkit-box-shadow: -4px -4px 21px -7px rgba(186, 153, 63, 0.72);
              -moz-box-shadow: -4px -4px 21px -7px rgba(186, 153, 63, 0.72);
            }

            .img-1 {
              width: 93%;
              /* height: 100%; */
              /* object-fit: scale-down; */
              /* object-position: 50% 0%; */
              height: 19%;

              transform: scale(0.8);
              transform: skew(-1deg, -1deg);
              transform: scaleY(-1) skew(113deg, 1deg);
              /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%); */
              -webkit-mask-image: -webkit-gradient(linear,
                  left top,
                  left bottom,
                  from(rgba(0, 0, 0, 1)),
                  to(rgba(0, 0, 0, 0)));
              mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
            }

            .img-3 {
              width: 100%;
              /* height: 100%; */
              object-fit: cover;
              object-position: 50% 0%;
              position: absolute;
              top: 14%;
              bottom: 0%;
              left: 0%;
              right: 0%;
              border-radius: 8px;
            }

            .img-4 {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: 50% 10%;
              position: absolute;
              top: 1%;
              bottom: 0%;
              left: 0%;
              right: 0%;
              border-radius: 8px;
            }
          }
        }

        .content2 {
          max-width: none;
          min-width: 0;
          grid-column-gap: 5rem;
          grid-row-gap: 5rem;
          flex-direction: column;
          grid-template-rows: auto;
          align-items: stretch;
          padding-bottom: 4rem;
          margin-top: 50px;

          .content-description {
            display: flex;
            line-height: 1;
            flex-direction: column;
            border-radius: 8px;
            background: linear-gradient(45deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #141518;
            box-shadow: 0px 60px 120px 0px rgba(0, 0, 0, 0.15);
            padding: 20px;

            .content-description-1 {
              color: #ffbf42;
              font-size: 1.2em;
              text-transform: uppercase;
              letter-spacing: 0.1em;
              margin-bottom: 25px;
              display: flex;
              font-weight: bold;
              margin-top: 20px;

              .fav-icon {
                color: #ffbf42;
                display: flex;
                justify-content: center;
                margin-right: 10px;
                height: 20px;
              }
            }

            .content-description-2 {
              color: white;
              font-size: 1.25rem;
              margin-bottom: 20px;
            }

            .content-description-3 {
              color: white;
              font-size: 1.25rem;
              margin-bottom: 20px;
              display: flex;
              margin-left: 20px;

              .circle-icon {
                color: #ffbf42;
                font-size: 17px;
                display: flex;
                justify-content: center;
                margin-right: 10px;
                margin-top: 5px;
              }
            }

            .content-description-4 {
              color: white;
              font-size: 1.25rem;
              margin-bottom: 30px;
            }

            .content-description-5 {
              color: white;
              font-size: 1.5rem;
              opacity: 0.7;
            }

            .content-description-6 {
              margin-top: 27px;
              color: gray;
              font-size: 1rem;
              opacity: 0.7;
            }
          }

          .img-wrap {
            height: 100%;
            grid-column-gap: 16px;
            grid-row-gap: 16px;
            border-radius: 2rem;
            grid-template-rows: auto auto;
            grid-template-columns: 1fr 1fr;
            grid-auto-columns: 1fr;
            justify-content: center;
            align-items: center;
            display: flex;
            position: relative;
            overflow: hidden;

            .img-2 {
              width: 93%;
              /* height: 100%; */
              /* object-fit: scale-down; */
              /* object-position: 50% 0%; */
              position: absolute;
              top: 14%;
              bottom: 0%;
              left: 0%;
              right: 0%;
              transform: scale(0.8);
              transform: skew(-1deg, -1deg);
              box-shadow: -4px -3px 23px -5px rgba(186, 153, 63, 0.46);
              -webkit-box-shadow: -4px -3px 23px -5px rgba(186, 153, 63, 0.46);
              -moz-box-shadow: -4px -3px 23px -5px rgba(186, 153, 63, 0.46);
            }

            .img-1 {
              width: 93%;
              /* height: 100%; */
              /* object-fit: scale-down; */
              /* object-position: 50% 0%; */
              height: 19%;
              position: absolute;
              top: 69%;
              bottom: 0%;
              left: 21%;
              right: 0%;
              transform: scale(0.8);
              transform: skew(-1deg, -1deg);
              transform: scaleY(-1) skew(113deg, 1deg);
              /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%); */
              -webkit-mask-image: -webkit-gradient(linear,
                  left top,
                  left bottom,
                  from(rgba(0, 0, 0, 1)),
                  to(rgba(0, 0, 0, 0)));
              mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
            }
          }
        }

        .gold-band {
          background-image: url("gold.webp");
          height: 75px;
          width: 100%;
          border-radius: 30px;
          margin-top: 20px;
          display: flex;
          align-items: center;
          align-content: center;
          padding-left: 3rem;
          background-size: cover;
          gap: 20px;
          font-family: Syne, sans-serif !important;
          margin-bottom: 20px;
          margin-top: 20px;

          .calendar {
            font-size: 40px;
          }

          .text {
            font-size: 1.75rem;
            font-family: Syne, sans-serif !important;
          }

          cursor: pointer;
        }

        .gold-band:hover {
          box-shadow: 0px 0px 20px 0px rgba(163, 156, 43, 0.71);
          -webkit-box-shadow: 0px 0px 20px 0px rgba(163, 156, 43, 0.71);
          -moz-box-shadow: 0px 0px 20px 0px rgba(163, 156, 43, 0.71);
          transform: scale(1.005);
        }
      }

      .footer {
        display: flex;
        justify-content: center;
        margin-top: 80px;
        margin-bottom: 40px;
        border-bottom: 1px solid #3e404c;

        .founders-logo {
          margin-bottom: 50px;
          width: 220px;
          display: flex;
          justify-content: center;
        }
      }

      .end {
        font-size: 14px;
        color: #444753;
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .img-wrap {
    order: -9999 !important;
    min-height: 26rem !important;
  }

  .content {
    grid-column-gap: 2rem !important;
    grid-row-gap: 2rem !important;
    grid-template-rows: auto auto !important;
    grid-template-columns: 1fr !important;
    grid-auto-flow: column !important;
  }

  .content1 {
    grid-column-gap: 2rem !important;
    grid-row-gap: 2rem !important;
    grid-template-rows: auto auto !important;
    grid-template-columns: 1fr !important;
    grid-auto-flow: column !important;

    .img-wrap {
      .img-1 {
        top: 118% !important;
        left: 0 !important;
      }

    }



  }

  .header {
    .sponsor-name {
      flex-direction: column !important;
    }
  }
}


@media screen and (max-width: 991px) {
  .img-wrap {
    order: -9999 !important;
    min-height: 26rem !important;
  }

  .content {
    grid-column-gap: 2rem !important;
    grid-row-gap: 2rem !important;
    grid-template-rows: auto auto !important;
    grid-template-columns: 1fr !important;
    grid-auto-flow: column !important;
  }

  .content1 {
    grid-column-gap: 2rem !important;
    grid-row-gap: 2rem !important;
    grid-template-rows: auto auto !important;
    grid-template-columns: 1fr !important;
    grid-auto-flow: column !important;

    .img-wrap {
      .img-1 {
        top: 118% !important;
        left: 0 !important;
      }

    }



  }

  .header {
    .sponsor-name {
      flex-direction: column !important;
    }
  }
}


@media screen and (max-width: 991px) {
  .container-navigation {
    flex-direction: column !important;

    .navigation-left {
      text-align: center;
      justify-content: center;
      margin-bottom: 20px;
    }

    .navigation-right {
      width: 100% !important;
      flex-direction: column !important;

      .sponsor-name {
        flex-direction: row !important;
      }
    }
  }
}

.app-loader {
  background: black;
  z-index: 9999999;
  font-weight: 600;
}
