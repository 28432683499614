.welcome-cont {
  text-align: center;

  .icon-cont {
    cursor: pointer;

    svg {
      width: 120px;
      height: 120px;
      color: var(--primary-color);
    }
  }

  h5 {
    font-weight: 600;
    font-size: 18px;
  }

  h4 {
    font-weight: 600;
    font-size: 22px;
    color: var(--primary-color);
  }
}
