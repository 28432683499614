.memberships {
  display: flex;
  gap: 35px;
  margin-bottom: 35px;

  .col {
    flex: 1;
    display: flex;
    flex-direction: column;

    &.right-col {
      max-width: 400px;
    }
  }

  .table {
    max-width: 100%;
  }
}

.transfrs-filter-select {
  border: none;
  margin-left: 10px;
  min-width: 164px;

  .select {
    border-radius: 8px;
    background-color: #121316;
    transition: 0.4s background;
    border: none;

    fieldset {
      transition: 0.4s border-color;
      border-width: 1px !important;
    }

    .clean {
      background-color: #121316;
    }

    &.focused {
      background-color: transparent;
    }

    &.error {
      border-color: brown !important;
      border-style: solid;
      border-width: 1px;
    }

    &.sm {
      height: 48px !important;
      padding: 0;
    }

    .MuiSelect-select {
      color: rgb(134, 134, 134);
      padding: 17.25px 29px;
      font-size: 16px;
    }

    svg {
      color: white;
    }
  }

  .form-input-error {
    font-size: 11px;
    color: rgb(204, 136, 136);
    // margin-left: 20px;
  }

}
