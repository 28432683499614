.card-container {
  height: auto;
  display: flex;
  flex-direction: column;

  .layout-card {
    border-radius: 8px;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%),
      #141518;
    box-shadow: 0px 60px 120px 0px rgba(0, 0, 0, 0.15);
  }

  &.centered-card {
    .card-main {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &.overflow-auto {
    overflow: auto;
  }
}
