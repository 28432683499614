.home {
  .table {
    .first-column {
      color: var(--primary-color)
    }
  }
  a{
    color: var(--primary-color);
  }
}

a{
  color: var(--primary-color);
}

.chatgpt-aq {
  .conversation {

    min-height: 300px;
    max-height: 450px;
    overflow: auto;

    .question-container {
      display: flex;
      flex-direction: row-reverse;
      gap: 10px;
      margin-bottom: 10px;

      .user-icon {
        background-color: gray;
        border-radius: 36px;
        font-size: 37px;
        padding: 7px;
      }

      .message {
        display: flex;
        flex-direction: column;

        .time {
          color: gray;
          font-size: 12px;
          display: flex;
          justify-content: end;
        }

        .question {
          background-color: #CB8F59;
          border-radius: 10px 0 10px 10px;
          padding: 15px 10px;
        }
      }
    }

    .answer-container {
      display: flex;
      gap: 4px;
      margin-bottom: 15px;

      img {
        height: 40px;
        display: flex;
        align-items: center;
      }

      .message {
        display: flex;
        flex-direction: column;

        .answer {
          background: #2B2D31;
          border-radius: 0 10px 10px 10px;
          padding: 15px 10px;
          margin-right: 40px;
          overflow-y: auto;
          width: fit-content;
        }

        .time {
          color: gray;
          font-size: 12px;
        }
      }
    }
  }
}
